import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from '../components/layout'
import BackgroundImage from 'gatsby-background-image'
import { gsap } from 'gsap'
import utils from "../components/utils";
import { renderRichText } from "gatsby-source-contentful/rich-text"
import ContactForm from "../components/contact-form";
import CircleLink from "../components/circle-link";
import SEO from "../components/seo";
import { options } from "../cms-helpers";


export default function ServiceTemplate({ data, entry }) {

  const service = data.contentfulService;
  const isLeft = entry && entry.state && entry.state.side && entry.state.side === 'left'


  useEffect(() => {
    utils.resetPage()

    gsap.to('body', {
      opacity: 1,
      duration: 0.5,
      ease: "none"
    })
    utils.fadeNav(false)
    gsap.to('#nav', {
      opacity: 1,
      duration: 0.7,
      ease: "power2.out"
    })
    gsap.to('.service-post-content', {
      opacity: 1,
      duration: 0.7,
      delay: 0.5,
      ease: "power2.out"
    })
  }, [])

  const content = (
    <div className={`relative`}>
      <div className="lg:min-h-screen flex flex-col px-4 lg:px-12 p-12 justify-end relative">
        <div className="lg:max-w-lg lg:absolute lg:top-1/2 lg:transform lg:-translate-y-1/2 pt-8 lg:pt-0">
          <div className="font-sc text-lg lg:text-xl capitalize mb-2 lg:mb-0">{service.title}</div>
        </div>
      </div>
      <section className={`service-post-content lg:transform lg:-translate-y-60`} style={{
        opacity: 0
      }}>
        <div className="px-4 lg:px-12 p-12 body-content">
          <div className="max-w-lg">
            {renderRichText(service.content, options)}

            <div className="mt-8">
              <CircleLink title="Go Back" isLink={true} to="/services" onPress={() => {
                utils.fadeOutFromPurple()
              }} />
            </div>
          </div>
        </div>
      </section>
    </div>
  )

  return (
    <Layout>
      <SEO
        title={service.meta.title}
        description={service.meta.description}
        image={service.meta.image.fluid.src}
      />
      {
        isLeft ? <section className="service-post lg:grid lg:grid-cols-2 gap-0 bg-sand">
          <div data-scroll data-scroll-sticky data-scroll-target=".service-post" className={`h-50vh lg:min-h-screen`}>
            <BackgroundImage backgroundColor={utils.getSandHex()} fluid={service.image.fluid} className={`w-full h-50vh lg:min-h-screen blog-post__image `} />
          </div>
          {content}
        </section> :
          <section className="service-post lg:grid lg:grid-cols-2 gap-0 bg-sand">
            <div className={`block lg:hidden h-50vh h-mobile-landscape-150vh lg:min-h-screen`}>
              <BackgroundImage backgroundColor={utils.getSandHex()} fluid={service.image.fluid} className={`w-full h-50vh h-mobile-landscape-150vh lg:min-h-screen blog-post__image `} />
            </div>
            {content}
            <div data-scroll data-scroll-sticky data-scroll-target=".service-post" className={`hidden lg:block h-50vh lg:min-h-screen`}>
              <BackgroundImage backgroundColor={utils.getSandHex()} fluid={service.image.fluid} className={`w-full h-50vh lg:min-h-screen blog-post__image `} />
            </div>
          </section>
      }
      <ContactForm />
    </Layout>
  )
}

export const query = graphql`
  query ServiceBySlug($slug: String!) {
    contentfulService(slug: { eq: $slug }) {
      meta {
        title
        description
        image {
          fluid {
            src
          }
        }
      }
      content {
        raw
      }
      order
      slug
      shortDescription {
        raw
      }
      title
      image {
        fluid(quality: 90, maxHeight: 1080) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`
